import { MenuItem, CTAPROIH } from '.'

export interface window {
    gtag: (...args: any[]) => void
}

export declare const window: Window & { dataLayer: Record<string, unknown>[] }

export type headerInterface = {
    wpMenu: MenuItem[]
    ctaPRO: CTAPROIH
    showAcessibility: boolean
    signupLink: string
    pageTributos?: boolean
    bannerTop?: BannerTop | null
}

export type BannerTop = {
    image: {
        mobile: string
        tablet: string
        desktop: string
    }
    url: string
    scroll?: boolean
}
