/* eslint-disable camelcase */
import Head from 'next/head'
import { GLOBAL_CONSTANT } from '../utils/constants'
import { decodeHtmlEntities } from '../utils/geral'

type MetadataProps = {
    title: string
    description: string
    image?: string
    url?: string
    revisitAfter?: string
    author?: string | null
    tag?: string | null
    yoast?: {
        title: string
        description: string
        keywords: string
    }
    dates?: {
        modification: string
        modification_GMT: string
        modification_iso8601: string
        publication: string
        publication_GMT: string
        publication_iso8601: string
    }
}

const logoJOTA = 'https://www.jota.info/images/meta/jotalogo.svg'
const JOTA = 'JOTA Jornalismo'

const Metadata = ({
    title,
    description,
    image = logoJOTA,
    url,
    revisitAfter = '1 minute',
    author = JOTA,
    tag,
    yoast,
    dates
}: MetadataProps) => {
    const regexAMPNONBETA = /(.*?)(\?non_beta=1|&non_beta=1|\?amp|&amp).*$/
    const urlTemp =
        typeof window !== 'undefined' ? window.location.href : (url ?? '')
    const urlWithoutDate = urlTemp.replace(/(.*)-\d{8}$/, '$1')
    const canonicalURL = urlWithoutDate.replace(regexAMPNONBETA, '$1')

    return (
        <Head>
            {/* META TAGS */}
            <meta charSet='utf-8' />
            <meta
                name='viewport'
                content='width=device-width initial-scale=1.0 maximum-scale=1.0 user-scalable=0'
            />
            <meta httpEquiv='X-UA-Compatible' content='ie=edge' />
            <meta name='cf-2fa-verify' content='ffe111bdb98aa1e' />
            <link rel='dns-prefetch' href='//c.jota.info' />

            {process.env.NEXT_PUBLIC_NODE_ENV == 'production' ? (
                <meta
                    name='robots'
                    content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
                />
            ) : (
                <meta name='robots' content='noindex' />
            )}

            <title>
                {yoast
                    ? decodeHtmlEntities(yoast.title)
                    : decodeHtmlEntities(title)}
            </title>

            <meta name='revisit-after' content={revisitAfter} />
            <meta name='publisher' content={JOTA} />
            <meta
                name='description'
                content={yoast ? yoast.description : description}
            />

            <meta name='author' content={author ?? JOTA} />

            {/* OG Tags */}
            <meta property='og:locale' content='pt_BR' />
            <meta property='og:type' content='article' />
            <meta
                property='og:title'
                content={
                    yoast
                        ? decodeHtmlEntities(yoast.title)
                        : decodeHtmlEntities(title ?? GLOBAL_CONSTANT.SITE_NAME)
                }
            />
            <meta
                property='og:description'
                content={yoast ? yoast.description : description}
            />
            <meta property='og:url' content={canonicalURL} />
            <meta property='og:site_name' content={JOTA} />
            <meta property='og:image' content={image} />
            <meta property='og:image:secure_url' content={image} />
            <meta property='og:author' content={author ?? JOTA} />
            <meta
                property='og:updated_time'
                content={dates?.modification_iso8601}
            />
            <meta
                property='og:published_time'
                content={dates?.publication_iso8601}
            />

            {/* Article Tags */}
            <meta
                property='article:publisher'
                content='https://www.facebook.com/jotajustica/'
            />
            <meta
                property='article:published_time'
                content={dates?.publication_iso8601}
            />

            <meta
                property='article:modified_time'
                content={dates?.modification_iso8601}
            />
            <meta property='article:section' content='Artigos' />
            <meta property='article:author' content={author ?? JOTA} />

            {/* Twitter Tags */}
            <meta property='twitter:card' content='summary_large_image' />
            <meta property='twitter:title' content={title} />
            <meta property='twitter:description' content={description} />
            <meta property='twitter:creator' content='@jotainfo' />
            <meta property='twitter:image' content={image} />

            <meta itemProp='image' content={image} />
            <meta property='fb:app_id' content='1309294866226576' />

            <meta
                name='generator'
                content='JOTA Jornalismo - https://www.jota.info'
            />

            {/* Link Tags */}
            <link rel='canonical' href={canonicalURL} />
            <link rel='icon' href='/favicon.ico' sizes='any' />

            <link
                rel='alternate'
                type='application/rss+xml'
                title='Feed do JOTA Jornalismo'
                href='https://portal.jota.info/feed/jotaunico'
            />

            <link
                rel='alternate'
                type='application/rss+xml'
                title='Feed do JOTA'
                href='https://www.jota.info/feed'
            />

            <script type='application/ld+json'>
                {`
                    {
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "NewsMediaOrganization",
                                "@id": "https://www.jota.info/#organization",
                                "name": "JOTA",
                                "url": "https://www.jota.info/",
                                "sameAs": [
                                    "https://www.facebook.com/jotajustica/",
                                    "https://www.instagram.com/jotaflash/",
                                    "https://www.linkedin.com/company/jotainfo",
                                    "https://www.youtube.com/jotalive",
                                    "https://twitter.com/jotainfo"
                                ],
                                "logo": {
                                    "@type": "ImageObject",
                                    "@id": "https://www.jota.info/#logo",
                                    "inLanguage": "pt-BR",
                                    "url": "https://images.jota.info/wp-content/uploads/2021/09/copy-of-jota-logo-high-cmyk.jpeg",
                                    "contentUrl": "https://images.jota.info/wp-content/uploads/2021/09/copy-of-jota-logo-high-cmyk.jpeg",
                                    "width": 1024,
                                    "height": 385,
                                    "caption": "JOTA"
                                },
                                "image": {
                                    "@id": "https://www.jota.info/#logo"
                                }
                            },
                            {
                                "@type": "WebSite",
                                "@id": "https://www.jota.info/#website",
                                "url": "https://www.jota.info/",
                                "name": "JOTA Info",
                                "description": "Por instituições mais previsíveis",
                                "publisher": {
                                    "@id": "https://www.jota.info/#organization"
                                },
                                "potentialAction": [
                                    {
                                        "@type": "SearchAction",
                                        "target": {
                                            "@type": "EntryPoint",
                                            "urlTemplate": "https://www.jota.info/busca/{search_term_string}"
                                        },
                                        "query-input": "required name=search_term_string"
                                    }
                                ],
                                "inLanguage": "pt-BR"
                            },
                            {
                                "@type": "WebPage",
                                "@id": "${canonicalURL}#webpage",
                                "url": "${canonicalURL}",
                                "name": "${yoast ? yoast.title : title}",
                                "isPartOf": {
                                    "@id": "https://www.jota.info/#website"
                                },
                                "datePublished": "${dates?.publication_iso8601}",
                                "dateModified": "${dates?.modification_iso8601}",
                                "description": "${yoast ? yoast.description : description}",
                                "breadcrumb": {
                                    "@id": "${canonicalURL}#breadcrumb"
                                },
                                "inLanguage": "pt-BR",
                                "potentialAction": [
                                    {
                                        "@type": "ReadAction",
                                        "target": [
                                            "${canonicalURL}"
                                        ]
                                    }
                                ]
                            },
                            {
                                "@type": "NewsArticle",
                                "@id": "${canonicalURL}#article",
                                "isPartOf": {
                                    "@id": "${canonicalURL}#webpage"
                                },
                                "headline": "${title}",
                                "datePublished": "${dates?.publication_iso8601}",
                                "dateModified": "${dates?.modification_iso8601}",
                                "mainEntityOfPage": {
                                    "@id": "${canonicalURL}#webpage"
                                },
                                "publisher": {
                                    "@id": "https://www.jota.info/#organization",
                                    "@type": "NewsMediaOrganization"
                                },
                                "articleSection": [
                                    "Artigos"
                                ],
                                "inLanguage": "pt-BR",
                                "copyrightYear": "${new Date().getFullYear()}",
                                "copyrightHolder": {
                                    "@id": "https://www.jota.info/#organization"
                                }
                            }
                        ]
                    }

                    `}
            </script>
        </Head>
    )
}

export default Metadata
