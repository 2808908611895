import React, { useState, useEffect, FC } from 'react'
import { CardDS } from '../..'
import { News, PlayerIH } from '../../../interfaces'
import styled from 'styled-components'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'

/**
 * HeadlineComponent Component
 * @param {PlayerIH} player
 * @return {React.JSX.Element}
 */
const HeadlineComponent: FC<{ player: PlayerIH; manchete: News }> = ({
    player,
    manchete
}): React.JSX.Element => {
    const [screenWidth, setScreenWidth] = useState<any>()

    useEffect(() => {
        if (window) {
            setScreenWidth(window.screen.width)
        }
    }, [])

    return (
        <YoutubeDiv>
            <iframe
                className='video'
                src={`https://www.youtube.com/embed/${player.player_link}?autoplay=1&cc_load_policy=1&mute=1`}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
            />

            <CardDS
                title={player.player_chamada}
                titleSearchTerm={''}
                size='large'
                tagLabel={player.player_chapeu}
                tagDisableHover
                type={screenWidth < 500 ? 'bleeding' : 'normal'}
                hasSeparator={screenWidth < 1199}
                newsLink={`${checkSpecialOrInfoNews(manchete.categories, manchete.slug, manchete.permalink)}`}
                titleDisableHover={false}
            />
        </YoutubeDiv>
    )
}

const YoutubeDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    & iframe {
        aspect-ratio: 16/9;
        width: 100%;
        border-radius: 4px;
    }
`

export default HeadlineComponent
