import styled from 'styled-components'
import { CardDS } from '../..'
import { FC } from 'react'
import { EditableSectionIH } from '../../../interfaces'
import { LAYOUT_CONSTANT } from '../../../utils/constants'

import { tokens } from '@JOTAJornalismo/jota-design-system'
import { generateUUID } from '@App/libs/utils/geral'
import router from 'next/router'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'

const { fonts, colors } = tokens

export const { SM_PX, MD_PX, LG_PX } = LAYOUT_CONSTANT.BREAKPOINTS

const CoberturasQuentes: FC<{ section: EditableSectionIH }> = ({
    section
}): React.JSX.Element => {
    return (
        <Container>
            <Title>{section.title}</Title>
            <EditableSectionSubtitle
                dangerouslySetInnerHTML={{ __html: section.subtitle }}
            />
            <List>
                {section?.content.map((newsItem) => (
                    <Item key={generateUUID()}>
                        <CardDS
                            image={newsItem?.image}
                            title={newsItem?.title}
                            titleSearchTerm={''}
                            text={newsItem.excerpt}
                            newsLink={`${checkSpecialOrInfoNews(
                                newsItem?.categories,
                                newsItem?.slug,
                                newsItem?.permalink
                            )}`}
                            multipleAuthors={
                                Array.isArray(newsItem?.authors)
                                    ? newsItem?.authors
                                    : []
                            }
                            multipleAuthorsClickEvent={(authorUrl) => {
                                if (authorUrl) {
                                    return router.push(`autor/${authorUrl}`)
                                }
                            }}
                            authorTagLabel={' '}
                            isVertical={true}
                            size='small'
                            tagLabel={newsItem?.hat}
                            tagOnClick={() => {
                                router.push(
                                    `${checkSpecialOrInfoNews(
                                        newsItem?.categories,
                                        newsItem?.slug,
                                        newsItem?.permalink
                                    )}`
                                )
                            }}
                        />
                    </Item>
                ))}
            </List>
        </Container>
    )
}

export default CoberturasQuentes

const Container = styled.section`
    padding: 0px 44px 88px 44px;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        padding: 44px 36px;
    }

    @media only screen and (max-width: ${SM_PX}) {
        padding: 44px 16px;
    }
`
const Title = styled.h4`
    ${fonts.jotaHeading04()};
    color: ${colors.black.jotaBlack};
    padding: 0 0 8px 0;
    margin: 0;
`

const List = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: start;
    gap: 44px;
    padding: 0;
    margin: 24px 0 0 0;

    @media only screen and (min-width: ${SM_PX}) and (max-width: ${MD_PX}) {
        grid-template-columns: 1fr;
    }

    @media only screen and (max-width: ${SM_PX}) {
        grid-template-columns: 1fr;
    }
`
const Item = styled.li`
    list-style-type: none;
    display: grid;
    gap: 16px;
`

const EditableSectionSubtitle = styled.p`
    margin: 0 !important;

    p {
        ${fonts.jotaBodyLarge()};
        color: ${colors.gray.jotaGrayDark};
        margin-block-start: 0;
        margin-block-end: 0;
        margin: 0 !important;
        padding: 0;
        padding-bottom: 8px;
        border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};
    }

    & > p > a {
        text-decoration: underline;
        color: ${colors.black.jotaBlack};
        font-weight: 700;
    }
`
