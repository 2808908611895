import React, { ReactElement, Suspense, useEffect, useMemo } from 'react'
import type { GetStaticPropsResult, NextPage } from 'next'

import {
    Header as HeaderComponent,
    HeadlineComponent,
    MiddleColumnComponent,
    BannerPROComponent,
    FooterComponent,
    LiveButtonComponent,
    Vertical,
    SeasonalBannerComponent,
    MostReadComponent,
    LastNewsComponent
} from '../libs/components'

import { useSelector } from 'react-redux'
import { StoreType } from '../api/types/store'
import {
    Body,
    FirstScroll,
    VerticaisContainer
} from '../libs/components/header/Header.styled'
import { HomePagePropsInterface } from '../libs/interfaces'
import { GLOBAL_CONSTANT, AUTH_CONSTANT } from '../libs/utils/constants'
import { Space44 } from '../libs/utils/styles/home.styles'
import PlayerComponent from '@App/libs/components/home/first-scroll/Player.component'
import {
    VerticaisDesc,
    VerticaisHeader,
    VerticaisTitle
} from '@App/libs/components/home/second-scroll/Vertical.component'
import Link from 'next/link'
import SpecialCoverageComponent from '@App/libs/components/home/third-scroll/specialCoverages'
import { LoadingHome } from '@App/libs/components/loading/Loading.component'
import OpinionComponent from '@App/libs/components/home/third-scroll/OpinionComponent'
import { vercelLog } from '@App/libs/utils/geral'
import CoberturasQuentes from '@App/libs/components/home/first-scroll/CoberturasQuentes'
import Metadata from '@App/libs/components/Metadata.component'
import { getFullImageHelper } from '@App/libs/components/home/first-scroll/Headline.component'
import dynamic from 'next/dynamic'

const Home: NextPage<HomePagePropsInterface> = ({
    banner,
    bannerPRO,
    bannerSazonal,
    first,
    footer,
    header,
    live,
    player,
    maisLidas,
    specialCoverage,
    pratas,
    second,
    ultimas,
    opiniaoAnalise,
    editableSection
}): ReactElement => {
    const [loading, setLoading] = React.useState<boolean>(true)
    const [show, setShow] = React.useState<boolean>(false)
    const { auth } = useSelector((state: StoreType) => state)

    const showProMessage = (isProUser: boolean) => {
        if (isProUser) {
            return (
                <VerticaisDesc>
                    {' '}
                    Cliente <strong>PRO</strong> tem acesso a conteúdos
                    exclusivos{' '}
                </VerticaisDesc>
            )
        } else {
            return (
                <VerticaisDesc>
                    Cliente <strong>PRO</strong> tem acesso a conteúdos
                    exclusivos.{' '}
                    <Link
                        href={
                            process.env.NEXT_PUBLIC_LP_PRO ??
                            'https://portal.jota.info/pro'
                        }
                        target='_blank'
                        rel='noreferrer'
                        data-test='be-pro-link'
                        id='verticais-seja-pro'
                    >
                        Seja PRO
                    </Link>
                    !
                </VerticaisDesc>
            )
        }
    }

    const isLoadedInfo = useMemo(() => {
        return !!(
            auth &&
            second &&
            specialCoverage &&
            maisLidas &&
            ultimas &&
            bannerPRO
        )
    }, [auth, bannerPRO, maisLidas, second, specialCoverage, ultimas])

    useEffect(() => {
        if (isLoadedInfo) {
            setShow(true)
            setLoading(false)
        } else {
            setShow(false)
            setLoading(true)
        }
    }, [isLoadedInfo])

    const JotaPROComponent = dynamic(
        () => import('../libs/components/home/first-scroll/JotaPRO.component'),
        {
            ssr: false,
            loading: () => <p>Carregando...</p>
        }
    )

    const PratasDaCasa = dynamic(
        () => import('../libs/components/home/second-scroll/PratasDaCasa'),
        {
            ssr: false,
            loading: () => <p>Carregando...</p>
        }
    )

    const BannerComponent = dynamic(
        () => import('../libs/components/home/Banner.component'),
        {
            ssr: false,
            loading: () => <p>Carregando...</p>
        }
    )

    return (
        <Suspense fallback={<LoadingHome />}>
            <div className='page'>
                {show && (
                    <>
                        <Metadata
                            title={GLOBAL_CONSTANT.SITE_NAME}
                            description={GLOBAL_CONSTANT.SITE_DESCRIPTION}
                            image={
                                typeof first?.manchete?.images === 'object'
                                    ? getFullImageHelper(
                                          first.manchete.images.desktop
                                      )
                                    : undefined
                            }
                        />

                        <Body id='body'>
                            {header && (
                                <HeaderComponent
                                    wpMenu={header.menu}
                                    ctaPRO={header.pro}
                                    showAcessibility={
                                        header.enableAccessibility
                                    }
                                    signupLink={header.assinePage}
                                    bannerTop={header.top_banner}
                                />
                            )}

                            {!loading ? (
                                <>
                                    {banner?.enable && (
                                        <BannerComponent
                                            isVisible={banner.enable}
                                            url={banner.url}
                                            images={banner.images}
                                        />
                                    )}

                                    {live?.is_active && (
                                        <LiveButtonComponent live={live} />
                                    )}

                                    <FirstScroll>
                                        {player?.player_ativo ? (
                                            <PlayerComponent
                                                player={player}
                                                manchete={first?.manchete}
                                            />
                                        ) : (
                                            <HeadlineComponent
                                                manchete={first?.manchete}
                                            />
                                        )}
                                        <MiddleColumnComponent
                                            destaques={first?.destaques}
                                        />
                                        <JotaPROComponent />
                                    </FirstScroll>

                                    {bannerPRO.enabled &&
                                    auth?.authState !=
                                        AUTH_CONSTANT.AUTH_STATE.USER_LOGGED ? (
                                        <BannerPROComponent
                                            bannerPRO={bannerPRO}
                                        />
                                    ) : (
                                        <Space44 />
                                    )}

                                    {editableSection.enabled ? (
                                        <CoberturasQuentes
                                            section={editableSection}
                                        />
                                    ) : (
                                        <PratasDaCasa
                                            Pratas={pratas}
                                            isAfterVerticals={false}
                                        />
                                    )}

                                    {/* Verticais */}
                                    <VerticaisHeader data-test='verticals-container'>
                                        <VerticaisTitle>
                                            Nossas verticais
                                        </VerticaisTitle>
                                        {showProMessage(auth.isProUser)}
                                    </VerticaisHeader>
                                    <VerticaisContainer>
                                        <Vertical
                                            news={second?.poder}
                                            vertical='Poder'
                                        />
                                        <Vertical
                                            news={second?.tributos}
                                            vertical='Tributos'
                                        />
                                        <Vertical
                                            news={second?.saude}
                                            vertical='Saúde'
                                        />
                                    </VerticaisContainer>

                                    {editableSection.enabled && (
                                        <PratasDaCasa
                                            Pratas={pratas}
                                            isAfterVerticals
                                        />
                                    )}

                                    <OpinionComponent
                                        opinionNews={opiniaoAnalise}
                                    />

                                    {specialCoverage.posts.length > 1 && (
                                        <SpecialCoverageComponent
                                            specialNews={specialCoverage}
                                        />
                                    )}

                                    {bannerSazonal?.enabled && (
                                        <SeasonalBannerComponent
                                            showBanner={true}
                                            bannerData={bannerSazonal}
                                        />
                                    )}

                                    {maisLidas && (
                                        <MostReadComponent
                                            maisLidas={maisLidas}
                                        />
                                    )}

                                    {ultimas && (
                                        <LastNewsComponent ultimas={ultimas} />
                                    )}

                                    {footer && (
                                        <FooterComponent footer={footer} />
                                    )}
                                </>
                            ) : (
                                <LoadingHome />
                            )}
                        </Body>
                    </>
                )}
            </div>
        </Suspense>
    )
}

export default Home

/**
 * getStaticProps
 * @return {Promise<GetStaticPropsResult<HomePagePropsInterface>>}
 */
export const getStaticProps = async (): Promise<
    GetStaticPropsResult<unknown>
> => {
    try {
        if (process.env.NEXT_PUBLIC_WP_API_REST) {
            vercelLog(`Buscando dados da API...`)
            const response = await fetch(process.env.NEXT_PUBLIC_WP_API_REST)

            if (!response.ok) {
                vercelLog(
                    `Erro ao buscar dados da API:` + response.statusText,
                    'error'
                )
                return {
                    props: {}
                }
            }

            let homeDataAPI

            try {
                homeDataAPI = await response.json()
            } catch (error) {
                vercelLog(`Erro ao analisar JSON da API:` + error, 'error')
                return {
                    props: {}
                }
            }

            const {
                banner,
                bannerPRO,
                bannerSazonal,
                first,
                footer,
                header,
                live,
                player,
                maisLidas,
                specialCoverage,
                opiniaoAnalise,
                pratas,
                second,
                ultimas,
                editableSection
            } = homeDataAPI

            return {
                props: {
                    banner,
                    bannerPRO,
                    bannerSazonal,
                    first,
                    footer,
                    header,
                    live,
                    player,
                    maisLidas,
                    opiniaoAnalise,
                    pratas,
                    second,
                    specialCoverage,
                    ultimas,
                    editableSection
                }
            }
        } else {
            vercelLog(
                `Variável de ambiente NEXT_PUBLIC_WP_API_REST não definida`,
                'error'
            )

            return {
                props: {}
            }
        }
    } catch (error) {
        vercelLog(`Erro ao buscar dados da API` + error, 'error')
        return {
            props: {}
        }
    }
}
