import styled from 'styled-components'
import { CardDS } from '../..'
import { FC } from 'react'
import { OpinionNews } from '../../../interfaces'
import { BREAKPOINTS } from '../../../utils/constants/layout'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import { VerticaisDesc } from '../second-scroll/Vertical.component'
import router from 'next/router'
const { colors, fonts, spaces } = tokens
import { addDatalayer } from '@App/libs/utils/geral'
import Link from 'next/link'
import { checkSpecialOrInfoNews } from '@App/libs/utils/constants/route'
import { PATROCINADO } from '@App/libs/utils/constants/home'
import { CONTEUDO_LIBERADO } from '../first-scroll/MiddleColumn.component'

/**
 * Mais Lidas Component
 * @return {React.JSX.Element}
 */
const OpinionComponent: FC<{
    opinionNews: OpinionNews
}> = ({ opinionNews }): React.JSX.Element => {
    return (
        <SpecialCoverageContainer>
            <Title> Opinião </Title>
            <VerticaisDesc>
                Colunas e artigos de autores convidados pelo JOTA
            </VerticaisDesc>
            <DivisorContainer>
                <OpinionAndArticlesContainer>
                    <h3>
                        <Link href={`/opiniao-e-analise/artigos`}>Artigos</Link>
                    </h3>
                    {opinionNews.artigos.map((newItem: any, index: number) => (
                        <div key={newItem.id + index}>
                            <CardDS
                                title={newItem.title}
                                titleSearchTerm={''}
                                newsLink={() => {
                                    addDatalayer({
                                        event: 'click_coberturas',
                                        news_link: `${checkSpecialOrInfoNews(
                                            newItem.categories,
                                            newItem.slug,
                                            newItem.link
                                        )}`
                                    })
                                    router.push(
                                        `${checkSpecialOrInfoNews(
                                            newItem.categories,
                                            newItem.slug,
                                            newItem.link
                                        )}`
                                    )
                                }}
                                tagLabel={newItem.hat}
                                isVertical={false}
                                tagDisableHover
                                size='small'
                                image={newItem.thumbnail}
                                multipleAuthors={newItem.author}
                                multipleAuthorsClickEvent={(authorUrl) => {
                                    if (authorUrl) {
                                        return router.push(
                                            `/autor/${authorUrl}`
                                        )
                                    }
                                }}
                                relatedTagLabel={newItem.category.name}
                                relatedTagOnClick={() =>
                                    router.push(`/opiniao-e-analise/artigos`)
                                }
                                subjectTagLabel={
                                    newItem.inherits_from_PRO
                                        ? CONTEUDO_LIBERADO
                                        : undefined
                                }
                                subjectTagDisableHover
                                isSponsored={
                                    (newItem.type === PATROCINADO ||
                                        newItem.inherits_from_PRO) ??
                                    false
                                }
                            />
                        </div>
                    ))}
                </OpinionAndArticlesContainer>
                <OpinionAndArticlesContainer>
                    <h3>
                        <Link href={`/opiniao-e-analise/colunas`}>Colunas</Link>
                    </h3>
                    {opinionNews.colunas.map((newItem: any, index: number) => (
                        <div key={newItem.id + index}>
                            <CardDS
                                title={newItem.title}
                                titleSearchTerm={''}
                                newsLink={() => {
                                    addDatalayer({
                                        event: 'click_coberturas',
                                        news_link: `${checkSpecialOrInfoNews(
                                            newItem.categories,
                                            newItem.slug,
                                            newItem.link
                                        )}`
                                    })
                                    router.push(
                                        `${checkSpecialOrInfoNews(
                                            newItem.categories,
                                            newItem.slug,
                                            newItem.link
                                        )}`
                                    )
                                }}
                                tagLabel={newItem.hat}
                                isVertical={false}
                                tagDisableHover
                                size='small'
                                image={newItem.thumbnail}
                                dateTagDisableHover
                                multipleAuthors={newItem.author}
                                multipleAuthorsClickEvent={(authorUrl) => {
                                    if (authorUrl) {
                                        return router.push(
                                            `/autor/${authorUrl}`
                                        )
                                    }
                                }}
                                authorTagOnClick={() => {
                                    router.push(
                                        `autor/${newItem.author[0].slug}`
                                    )
                                }}
                                relatedTagLabel={newItem.category.name}
                                relatedTagOnClick={() =>
                                    router.push(
                                        `/opiniao-e-analise/colunas/${newItem.category.slug}`
                                    )
                                }
                                subjectTagLabel={
                                    newItem.inherits_from_PRO
                                        ? CONTEUDO_LIBERADO
                                        : undefined
                                }
                                subjectTagDisableHover
                                isSponsored={
                                    (newItem.type === PATROCINADO ||
                                        newItem.inherits_from_PRO) ??
                                    false
                                }
                            />
                        </div>
                    ))}
                </OpinionAndArticlesContainer>
            </DivisorContainer>
        </SpecialCoverageContainer>
    )
}

export default OpinionComponent

const SpecialCoverageContainer = styled.div`
    box-sizing: border-box;
    padding-inline: 44px;
    margin-block: 44px;

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        padding-inline: 36px;
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        padding-inline: 16px;
    }
`
const Title = styled.h4`
    ${fonts.jotaHeading04};
    color: ${colors.black.jotaBlack};
    margin: 0;
`

const OpinionAndArticlesContainer = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font: ${fonts.jotaHeading05};
        margin: 0;
        margin-top: 24px;
    }

    & > div {
        padding-block: ${spaces.jotaSpacing24};
        border-bottom: 1px solid ${colors.transparent.jotaTGrayLight40};
    }

    & > div:last-child {
        border-bottom: none;
    }

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        grid-template-columns: repeat(1, 1fr);
        gap: ${spaces.jotaSpacing24};

        & > div {
            padding-block: 0;
        }
    }

    @media only screen and (max-width: ${BREAKPOINTS.SM_PX}) {
        grid-template-columns: repeat(1, 1fr);
        gap: ${spaces.jotaSpacing24};
    }
`

const DivisorContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spaces.jotaSpacing24};

    @media only screen and (max-width: ${BREAKPOINTS.MD_PX}) {
        display: flex;
        flex-direction: column;
    }
`
